import React, { useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// project imports
import useScriptRef from "../../../hooks/useScriptRef";
import AnimateButton from "../../../ui-component/extended/AnimateButton";
// assets
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Navigate, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { Auth } from "aws-amplify";
import _ from "lodash";
import { authActions } from "../../../redux/auth";
import { Alert, AlertTitle } from "@material-ui/core";
import instance, { fetchUserProfile } from "../../../api";
import { setUser } from "../../../redux/auth/operations";
import { CircularProgress } from "@mui/material";
import { URL } from "Constants/routes";

const AuthForgotPassword = ({ ...others }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [codeSent, setCodeSent] = useState(false);
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoginError, setLoginError] = useState("");
  const [isLoading, setLoading] = useState(false);

  const isLoggedIn = useSelector(
    (state) => !!(state.auth.user && state.auth.profile)
  );

  const onSubmitEmail = async (email) => {
    try {
      setLoading(true);
      const response = await Auth.forgotPassword(email);
      setEmail(email);
      setCodeSent(true);
    } catch (e) {
      alert("Error", "Code you entered is not correct!");
    } finally {
      setLoading(false);
    }
  };
  const onSubmitPassword = async (code, password) => {
    try {
      setLoading(true);
      const response = await Auth.forgotPasswordSubmit(email, code, password);
      console.log(
        "🚀 ~ file: AuthForgotPassword.js ~ line 76 ~ onSubmitPassword ~ response",
        response
      );
      // await Auth.signIn({
      //   email,
      //   password,
      // });
      navigate(URL.AUTH.LOGIN);
    } catch (e) {
      alert("Error", "Invalid code provided, please enter the correct code!");
    } finally {
      setLoading(false);
    }
  };

  if (isLoggedIn) return <Navigate to="/home" replace />;

  return (
    <>
      {isLoginError ? (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {isLoginError}
        </Alert>
      ) : undefined}
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" textAlign="center">
              Enter your email address to reset <br />
              your password
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {!codeSent ? (
        <Formik
          initialValues={{
            email: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            onSubmitEmail(values.email);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit} {...others}>
              <FormControl
                fullWidth
                error={Boolean(touched.email && errors.email)}
                sx={{ ...theme.typography.customInput }}
              >
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Email Address / Username
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-email-login"
                  type="email"
                  value={values.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Email Address / Username"
                  inputProps={{}}
                />
                {touched.email && errors.email && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-email-login"
                  >
                    {errors.email}
                  </FormHelperText>
                )}
              </FormControl>
              <Box sx={{ mt: 2 }}>
                <AnimateButton>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                  >
                    {isLoading ? (
                      <CircularProgress color="warning" size={20} />
                    ) : (
                      "Send Code"
                    )}
                  </Button>
                </AnimateButton>
              </Box>
            </form>
          )}
        </Formik>
      ) : (
        <Formik
          initialValues={{
            code: "",
            password: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            code: Yup.string("Must be a valid string")
              .max(8)
              .required("Code is required"),
            password: Yup.string().max(255).required("Password is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            onSubmitPassword(values.code, values.password);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit} {...others}>
              <FormControl
                fullWidth
                error={Boolean(touched.code && errors.code)}
                sx={{ ...theme.typography.customInput }}
              >
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Code
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-email-login"
                  type="code"
                  value={values.code}
                  name="code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Code"
                  inputProps={{}}
                />
                {touched.code && errors.code && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-email-login"
                  >
                    {errors.code}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl
                fullWidth
                error={Boolean(touched.password && errors.password)}
                sx={{ ...theme.typography.customInput }}
              >
                <InputLabel htmlFor="outlined-adornment-password-login">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password-login"
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  inputProps={{}}
                />
                <div
                  style={{
                    marginTop: 10,
                    cursor: "pointer",
                    textDecorationLine: "underline",
                  }}
                  onClick={() => setCodeSent(false)}
                >
                  <Typography variant="subtitle1">Resend Code?</Typography>
                </div>
                {touched.password && errors.password && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-password-login"
                  >
                    {errors.password}
                  </FormHelperText>
                )}
              </FormControl>
              <Box sx={{ mt: 2 }}>
                <AnimateButton>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                  >
                    {isLoading ? (
                      <CircularProgress color="warning" size={20} />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </AnimateButton>
              </Box>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default AuthForgotPassword;
