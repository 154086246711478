import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Typography, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import background from "Assets/images/grouped.png";
import styles from "./styles.css";
import { useTheme } from "@mui/material/styles";
import CustomAvatar from "ui-component/Avatar";

export const SuggestedCelebritiesList = () => {
  const suggestedCelebritiesList = useSelector(
    (state) => state.videos.suggestedCelebritiesList
  );
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div
      style={{
      //  backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: 120,
        backgroundRepeat: "no-repeat",
        justifyContent: "center",
        padding: 2,
      }}
    >
      <div class="container">
        {suggestedCelebritiesList.map((value) => (
          <div>
            <Link
              to={"/public-profile/" + value.username}
              className="username-link"
            >
              <CustomAvatar
                sx={{
                  height: 80,
                  width: 80,
                }}
                item={{
                  username: value.nickname,
                  image: value.image,
                  publisher: value.publisher,
                }}
              />
            </Link>
            <Typography
              color={theme.palette.secondary.main}
              gutterBottom
              sx={{ textAlign: "center", marginTop: 1 }}
              variant={"h4"}
            >
              {value.name}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};
