import { Auth, Storage } from "aws-amplify";
import { feedsOperations } from "../feeds";
import { notificationsOperations } from "../notifications";
import { videosOperations } from "../videos";
import * as authActions from "./actions";
import _ from "lodash";
import { setSuperProperties, trackUserType } from "../../utils/tracks";
import { usersOperations } from "../users";
import {
  patchUserProfile,
  fetchUserProfile,
  configureToken,
  fetchGetStreamToken,
} from "api";
export const initializeApp = () => (dispatch) => {
  dispatch(authActions.setAppInit(true));
};

export const setUser =
  (user) =>
  async (
    dispatch
    //, getState, { api, setError }
  ) => {
    try {
      dispatch(authActions.setUser(user));
      const signInUserSession = await Auth.currentSession();

      const tokens = _.get(signInUserSession, "accessToken.jwtToken", null);

      //setSuperProperties();
      const { token } = await fetchGetStreamToken();

      dispatch(authActions.setGetStreamToken(token));

      await initAuthData();

      return tokens;
    } catch (e) {
      // dispatch(setError(e));
      return false;
    }
  };

export const initAuthData =
  () =>
  async (
    dispatch
    // getState, { api, setError, actions, mixpanel  }
  ) => {
    // const { user } = getState().auth;
    // if (!user) {
    //   return false;
    // }

    try {
      // const userId = _.get(user, "username");
      // mixpanel.identify(userId);
      //  mixpanel.track("event test");
      //   setSuperProperties();
      //  trackUserType();

      dispatch(authActions.setFetching(true));

      await dispatch(videosOperations.fetchVideoCategories());
      await dispatch(fetchProfile());

      await dispatch(usersOperations.fetchBlockedUsers());

      const { token } = await fetchGetStreamToken();
      console.log("token", token);
      dispatch(authActions.setGetStreamToken(token));

      dispatch(notificationsOperations.updateFCMTokenIfNeeded());

      // const initialUrl = await Linking.getInitialURL();
      // if (initialUrl) {
      //   dispatch(onInitialUrl(initialUrl));
      // }

      // const initialRemoteMessage = await messaging().getInitialNotification()
      // if (initialRemoteMessage) {
      //   dispatch(notificationsOperations.onInitialNotification(initialRemoteMessage))
      // }

      return true;
    } catch (e) {
      // dispatch(setError(e));
      return false;
    } finally {
      //dispatch(authActions.setFetching(false));
    }
  };

export const doLogout =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      await api.patchUserProfile({ deviceToken: null });
    } catch (e) {
    } finally {
      dispatch(logout());
    }
  };

export const logout = () => async (dispatch) => {
  dispatch(authActions.logout());
  configureToken(null);
  Auth.signOut();
};

export const updateUserProfile = (data) => async (dispatch) => {
  if (!data || _.isEmpty(data)) {
    return false;
  }

  // const lang = i18n.locale;
  const dataWithLanguage = { ...data, language: "en" };
  try {
    await patchUserProfile(dataWithLanguage);
    await dispatch(fetchProfile());

    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    dispatch(setUser(user));
    //  setSuperProperties();
    // dispatch(feedsOperations.refreshFeeds());
    return true;
  } catch (e) {
    // dispatch(setError(e));
    return false;
  }
};

export const postProfileImage = async ({ imageUri, fileName, type }) => {
  if (!imageUri) {
    return;
  }
  try {
    const response = await fetch(imageUri);
    const blob = await response.blob();
    const fileResult = Storage.put(fileName, blob, {
      contentType: type,
    });
    return fileResult;
  } catch (e) {
    return null;
  }
};

// const onInitialUrl =
//   (initialUrl) =>
//   async (dispatch, getState, { actions }) => {
//     if (initialUrl.includes("bethey://video")) {
//       const { username, activityId } = qs.parse(
//         initialUrl.replace("bethey://video?", "")
//       );
//       if (username && activityId) {
//         actions.push("FeedActivity", { activityId, username });
//       }
//     }
//   };

export const fetchProfile = () => async (dispatch, getState) => {
  const { profile } = getState().auth;
  if (!profile) {
    return;
  }
  try {
    dispatch(authActions.setFetching(true));
    const username = _.get(profile, "username");

    const userProfile = await fetchUserProfile(username);

    dispatch(authActions.setProfile(userProfile));

    // setSuperProperties();
    return userProfile;
  } catch (e) {
    // dispatch(setError(e));
    return null;
  } finally {
    dispatch(authActions.setFetching(false));
  }
};

export const refreshAuthenticatedUser = () => async (dispatch, getState) => {
  const { user } = getState().auth;
  if (user) {
    try {
      const authUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      dispatch(setUser(authUser));
      setSuperProperties();
      trackUserType();
    } catch (e) {}
  }
};

export const refreshUserAndSession = () => async (dispatch, getState) => {
  const { user } = getState().auth;

  if (user) {
    try {
      const currentSession = user.signInUserSession;
      user.refreshSession(currentSession.refreshToken, async (err, session) => {
        if (!err) {
          const authUser = await Auth.currentAuthenticatedUser({
            bypassCache: true,
          });

          dispatch(setUser(authUser));
          setSuperProperties();
          // Actions.refresh("FeedUser");s
          trackUserType();
        }
      });
    } catch (e) {}
  }
};

export const onPostTermsAcceptation =
  (username) =>
  async (dispatch, getState, { api, setError }) => {
    const termsAccepted = { termsAndConditions: true };
    try {
      await api.patchUserProfile(termsAccepted);
      const userProfile = await api.fetchUserProfile(username);
      dispatch(authActions.setProfile(userProfile));
      dispatch(refreshUserAndSession());
      return true;
    } catch (e) {
      dispatch(setError(e));
      return false;
    }
  };
