
import React from 'react';

import {

  FlatFeed,

  InfiniteScrollPaginator,
} from 'react-activity-feed';
import _ from 'lodash'
import { NewActivitiesNotification } from 'components/atoms/new-activities-notification/view'
import { RenderVideo } from "../../Home/HomeComponents/VideoView";

import { FeedEmptyComponent } from 'ui-component/FeedEmptyComponent';
function UserLikesTab(props) {



  return (
    <div >
      <FlatFeed
        notify
        feedGroup={props.feedGroup}
        options={props.options}

        doFeedRequest={async (client, feedGroup, userId, options) => {
          const res = await client.reactions.filter(options)
          const results = _.get(res, 'results', [])
          if (!_.size(results)) {

            return res
          }

          const activitiesIds = _.map(results, v => _.get(v, 'activity_id'))
          const activities = await client.getActivities({ ...options, ids: activitiesIds })

          return activities
        }}
        Placeholder={() => <FeedEmptyComponent />}
        Paginator={InfiniteScrollPaginator}
        Notifier={props => <NewActivitiesNotification {...props} />}
        Activity={props => {

          return <RenderVideo
            item={props.activity}
            isUrl={true
            }
            idx={props?.activity?.id}
          />

        }}


      />

    </div>
  );
}
export default UserLikesTab;
