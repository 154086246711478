export const ENV = process.env.REACT_APP_ENVIRONMENT
  ? process.env.REACT_APP_ENVIRONMENT
  : "STG";

const BASE_URL_PRO = "https://api.bethey.com";
const BASE_URL_DEV = "https://api.dev.bethey.com";
const BASE_URL_PRE_AUDIT = "https://api.pre.bethey.com";
const BASE_URL_PRE = "https://api.development.bethey.deploy-cd.com";
const BASE_URL_STG = "https://api.stg.bethey.com";

const WEB_BASE_URL_PRO = "https://www.bethey.com";
const WEB_BASE_URL_DEV = "https://www.dev.bethey.com";
const WEB_BASE_URL_PRE_AUDIT = "https://www.pre.bethey.com";
const WEB_BASE_URL_PRE = "https://www.development.bethey.deploy-cd.com";
const WEB_BASE_URL_STG = "https://my.stg.bethey.com";

const LANDING_URL_STG = "https://video.stg.bethey.com/";
const LANDING_URL_DEV = "https://video.dev.bethey.com/";
const LANDING_URL_PRO = "https://video.bethey.com/";

export const WEB_BASE_URL_TERMS = "https://www.bethey.com";

export const WEB_BASE_URL =
  ENV === "PRO"
    ? WEB_BASE_URL_PRO
    : ENV === "STG"
    ? WEB_BASE_URL_STG
    : ENV === "DEV"
    ? WEB_BASE_URL_DEV
    : ENV === "PRE"
    ? WEB_BASE_URL_PRE
    : ENV === "AUD"
    ? WEB_BASE_URL_PRE_AUDIT
    : null;
export const BASE_URL =
  ENV === "PRO"
    ? BASE_URL_PRO
    : ENV === "STG"
    ? BASE_URL_STG
    : ENV === "DEV"
    ? BASE_URL_DEV
    : ENV === "PRE"
    ? BASE_URL_PRE
    : ENV === "AUD"
    ? BASE_URL_PRE_AUDIT
    : null;

export const LANDING_URL =
  ENV === "DEV"
    ? LANDING_URL_DEV
    : ENV === "PRO"
    ? LANDING_URL_PRO
    : LANDING_URL_STG;
