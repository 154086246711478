import React, { useState, useEffect, useRef } from "react";
import FlatList, { PlainList } from "flatlist-react";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import SearchSection from "layout/MainLayout/Header/SearchSection";
import { RenderVideo } from "Pages/Home/HomeComponents/VideoView";
import Loading from "ui-component/Loading";
import { Grid, Button, useMediaQuery } from "@mui/material";
import { useDebounce } from "utils/commonHooks";
import { fetchSearchVideos, fetchSearchUsers, getBlockedUsers } from "api";
import MainLayout from "layout/MainLayout";
import _ from "lodash";
const DEBOUNCE_DELAY = 750;
function Search(props) {
  const [value, setValue] = useState("");
  const debounceFilterText = useDebounce(value, DEBOUNCE_DELAY);
  const theme = useTheme();
  //    const [category, setCategory] = React.useState(props.category)
  const videosTotalPage = useRef(1);
  const videosPage = useRef(1);

  const [videosTotal, setVideosTotal] = React.useState(1);
  const [videosList, setVideosList] = React.useState([]);
  const [videosLoading, setVideosLoading] = React.useState(false);
  const dispatch = useDispatch();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const listInnerRef = useRef();
  const onScroll = function () {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      onEndReached(videosList);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  const initVideosList = React.useCallback(() => {
    const newList = [];
    const newPage = 1;
    setVideosList(newList);
    videosPage.current = newPage;

    fetchVideosList(newList, newPage);
    setValue((prev) => "");
  }, []);

  //n end REach

  const onEndReached = (videosList) => {
    const loadNextVideoPage =
      videosPage.current < videosTotalPage.current &&
      !videosLoading &&
      videosList.length > 0;

    if (loadNextVideoPage) {
      const newPage = videosPage.current + 1;

      videosPage.current = newPage;

      fetchVideosList(videosList, newPage, "");
    
    }
  };
  const fetchVideosList = React.useCallback(
    async (currentList, currentPage, searchTextInput) => {
      try {
        setVideosLoading(true);
        const params = { terms: searchTextInput, page: currentPage };
        const fetchSearchVideosRes = await fetchSearchVideos(params);

        const total = _.get(fetchSearchVideosRes, "pageCount", 1);
        const data = [
          ...currentList,
          ..._.get(fetchSearchVideosRes, "data", []),
        ];
        setVideosList(data);
        videosTotalPage.current = total;
        setVideosTotal(total);
      } catch (e) {
      } finally {
        setVideosLoading(false);
      }
    },
    [props]
  );

  React.useEffect(() => {
    fetchVideosList([], 1, debounceFilterText);
  }, [fetchVideosList, debounceFilterText]);

  return (
    <MainLayout search={true}>
      <div
        style={{
          display: "flex",
          width: matchDownSM ? "100%" : "100%",
        }}
      >
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <SearchSection search={true} value={value} setValue={setValue} />
            <Button
              variant="contained"
              sx={{
                marginLeft: 2,
                borderRadius: 10,
                padding: 1,
                width: matchDownSM ? "100px" : "120px",
              }}
              onClick={() => {
                initVideosList();
              }}
            >
              Reset
            </Button>
          </div>

          <Grid container spacing={0} columns={{ xs: 2, sm: 8, md: 12 }}>
            <PlainList
              renderOnScroll
              list={videosList}
              renderItem={(item) => <RenderVideo item={item} search={true} />}
              renderWhenEmpty={() => null}
            />
          </Grid>
          {videosLoading ? (
            <Loading isLoading={true} />
          ) : !videosLoading && videosList.length == 0 ? (
            <Loading isEmpty={true} />
          ) : null}
        </div>
      </div>
    </MainLayout>
  );
}
export default Search;

