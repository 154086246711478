import React from "react";

import { FlatFeed, InfiniteScrollPaginator } from "react-activity-feed";
import _ from "lodash";
import { NewActivitiesNotification } from "components/atoms/new-activities-notification/view";
import { FeedEmptyComponent } from "ui-component/FeedEmptyComponent";
import { RenderVideo } from "../../Home/HomeComponents/VideoView";

function TimeLineVideoView(props) {
  const fromFollowing = props?.fromFollowing ? props?.fromFollowing : false;
  const renderVideoView = (props) => {


    return (
      <RenderVideo
        fromFollowing={fromFollowing}
        item={props.activity}
        isUrl={true}
        idx={props?.activity?.id}
      />
    );
  };
  return (
    <div>
      <FlatFeed
        Placeholder={() => <FeedEmptyComponent />}
        notify
        feedGroup={props.feedGroup}
        options={props.options}
        Paginator={InfiniteScrollPaginator}
        userId={props.username}
        Notifier={(props) => <NewActivitiesNotification {...props} />}
        Activity={(props) => renderVideoView(props)}
      />
    </div>
  );
}
export default TimeLineVideoView;
