import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { Avatar, ListItemAvatar, Badge } from "@mui/material";
import React from "react";
import UserAvatar from "Assets/images/avatar.png";
function CustomAvatar(props) {
  const item = props?.item;

  return (
    <div className="App">
      <ListItemAvatar sx={{ marginRight: 2 }}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            item.publisher ? (
              <CheckCircleIcon
                sx={{
                  color: "#008abf",
                  borderRadius: "50%",
                  backgroundColor: "#ffff",
                }}
              />
            ) : null
          }
        >
          <Avatar
            sx={
              props.sx
                ? props.sx
                : {
                    height: "70px",
                    width: "70px",
                  }
            }
            alt={item.username}
            src={item.image ? item.image : UserAvatar}
          />
        </Badge>
      </ListItemAvatar>
    </div>
  );
}
export default CustomAvatar;
