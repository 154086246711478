import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { videosOperations, videosActions } from "redux/videos";
import FlatList, { PlainList } from "flatlist-react";
import styles from "./HomeComponents/styles.css";
import { dashbaord, form } from "Constants/appStrings";
import TimeLineVideoView from "./../PublicProfiles/VideosFeed/TimeLineVideoView";
import { Grid, useMediaQuery, Divider, Typography } from "@mui/material";

import { RenderVideo } from "./HomeComponents/VideoView";
import { useTheme } from "@mui/material/styles";
import Loading from "ui-component/Loading";

import { SuggestedCelebritiesList } from "./HomeComponents/suggestedCelebritiesList";
const LIMIT = 20;

const TimelineType = Object.freeze({
  Discover: "discover",
  Following: "following",
});
export default function Home(props) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const displaySuggestedPage = useRef(0);
  const hasMoreItems = useRef(0);

  // hasMoreItems: false,
  const dispatch = useDispatch();

  const [suggestedVideos, setSuggestedVideos] = useState([]);

  const [displaySuggested, setDisplaySuggested] = useState(false);
  const [scrolled, setSrolled] = useState(false);
  const [isFollowingVideo, setIsFollowingVideo] = useState(true);
  const [type, setType] = useState(TimelineType.Following);
  const onScroll = function () {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (displaySuggestedPage.current < hasMoreItems.current + 1) {
        //  _getDiscoverVideos()
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  const handleChangeTimelineType = async (tab) => {
    if (
      (tab === 0 && type === TimelineType.Following) ||
      (tab === 1 && type === TimelineType.Discover)
    ) {
      return;
    }

    displaySuggestedPage.current = 1;
    setSrolled(false);

    setType(tab === 1 ? TimelineType.Discover : TimelineType.Following);

    if (tab == 1) {
      await _getDiscoverVideos();
    }
  };

  const _getDiscoverVideos = async () => {
    const newItems = await props.getVideosToDiscover(
      displaySuggestedPage.current,
      LIMIT
    );

    hasMoreItems.current = newItems.pageCount;

    // let items = displaySuggestedPage.current === 1 ?
    //newItems.data
    //: [...suggestedVideos, ...newItems.data]

    if (displaySuggestedPage.current == 1) {
      setSuggestedVideos(newItems.data);
    } else {
      setSuggestedVideos([...suggestedVideos, ...newItems.data]);
    }

    displaySuggestedPage.current = displaySuggestedPage.current + 1;
    setDisplaySuggested(true);
  };

  return (
    <div style={{ backgroundColor: "#ffff" }}>
      <SuggestedCelebritiesList />

      <Grid
        item
        xs={4}
        align="center"
        justify="center"
        alignItems="center"
        sx={{ display: "flex" }}
      >
        <div style={{ display: "flex", margin: "auto" }}>
          <Typography
            onClick={
              () => handleChangeTimelineType(0)
              //handleVideosType(dashbaord.Following)
            }
            sx={{
              display: "inline",
              margin: "1rem",
              cursor: "pointer",
            }}
            component="span"
            variant="h3"
            color={type === TimelineType.Following ? "primary" : "text.primary"}
          >
            {dashbaord.Following}
          </Typography>

          <Divider
            sx={{
              borderRightWidth: 2,
              borderColor: "red",
              marginY: 2,
              height: "1.5rem",
            }}
            orientation="vertical"
            flexItem
          />

          <Typography
            onClick={() => handleChangeTimelineType(1)}
            sx={{
              display: "inline",
              margin: "1rem",
              cursor: "pointer",
            }}
            component="span"
            variant="h3"
            color={type === TimelineType.Discover ? "primary" : "text.primary"}
          >
            {dashbaord.Discover}
          </Typography>
        </div>
      </Grid>

      <div className="App">
        {type === TimelineType.Following ? (
          props.myProfile && props.myProfile.followingCount === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Typography textAlign={"center"} width={"50%"} variant="h5">
                {form.noFollow}
              </Typography>
            </div>
          ) : (
            <TimeLineVideoView
              fromFollowing={true}
              options={{
                limit: 20,
              }}
              feedGroup="timeline"
              username={props.myProfile.username}
            />
          )
        ) : (
          <PlainList
            list={suggestedVideos}
            renderItem={(item) => (
              <RenderVideo canFollow={true} discover={true} item={item} />
            )}
            renderWhenEmpty={() => <Loading isEmpty={true} />}
          />
        )}
      </div>
    </div>
  );
}
