import React from "react";
import {
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import "./index.scss";
import CustomAvatar from "ui-component/Avatar";
import { useDispatch } from "react-redux";
import { followUser, unfollowUser } from "redux/users/operations";
import { useTheme } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";

const DiscoverFollowedCard = ({
  index,
  item,
  sx,
  search,
  isHome,
  discover,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

  const [following, setFollowing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const onUnfollow = async (username) => {
    setLoading(true);
    const success = dispatch(unfollowUser(username));

    if (success) {
      setFollowing(false);
      // fetchMyProfile()
    }
    setLoading(false);
  };

  const onFollow = async (username) => {
    setLoading(true);
    const success = dispatch(followUser(username));

    if (success) {
      setFollowing(true);
      // fetchMyProfile()
    }
    setLoading(false);
  };

  return (
    <ListItem
      sx={{
        padding: 0,
      }}
      alignItems="flex-start"
      key={index}
    >
      <ListItem
        secondaryAction={
          isHome || search ? undefined : (
            <LoadingButton
              loading={loading}
              variant={following ? "outlined" : "contained"}
              sx={{ borderRadius: 10, width: matchDownSM ? "100px" : "120px" }}
              onClick={() => {
                following ? onUnfollow(item.username) : onFollow(item.username);
                // dispatch(followUser(item.username));
              }}
            >
              {/* {item.currentlyFollowing ? "Unfollow" : "Follow"} */}
              {following ? "Unfollow" : "Follow"}
            </LoadingButton>
          )
        }
        sx={{
          padding: 1,
        }}
      >
        <Link to={"/public-profile/" + item.username} className="username-link">
          <CustomAvatar item={item} sx={sx} />

          <ListItemText
            primary={item?.nickname}
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {item.name}
                </Typography>
              </React.Fragment>
            }
          />
        </Link>
      </ListItem>
    </ListItem>
  );
};

export default DiscoverFollowedCard;
