export const SET_AUTH_INIT = "@auth/SET_AUTH_INIT";
export const SET_STREAM_TOKEN = "@auth/SET_STREAM_TOKEN";
export const SET_AUTH_FETCHING = "@auth/SET_AUTH_FETCHING";
export const SET_AUTH_USER = "@auth/SET_AUTH_USER";
export const SET_AUTH_PROFILE = "@auth/SET_AUTH_PROFILE";
export const SET_AUTH_GETSTREAM_TOKEN = "@auth/SET_AUTH_GETSTREAM_TOKEN";
export const SET_INVITATION_CODE = "@auth/SET_INVITATION_CODE";
export const SET_USER_SESSION = "@auth/SET_USER_SESSION";
export const START_APP = "@auth/START_APP";
export const SET_HAS_OPEN_APP_BEFORE = "@auth/SET_HAS_OPEN_APP_BEFORE";

export const LOGOUT = "LOGOUT";
