import React, { useState, useEffect } from "react";
// material-ui
import { useTheme } from "@mui/material/styles";
import { Grid, Stack, Typography, useMediaQuery, Avatar } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Card from "@mui/material/Card";

import { Box, Button, Paper } from "@mui/material";
import CustomAvatar from "ui-component/Avatar";
import Loading from "ui-component/Loading/";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import YouTubeIcon from "@mui/icons-material/YouTube";

import SwipeableViews from "react-swipeable-views";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Image from "Assets/images/backcover.jpeg";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "api";
import Followings from "Pages/Profile/Followings";
import UserLikesTab from "./VideosFeed/view";
import TimeLineVideoView from "./VideosFeed/TimeLineVideoView";
import { followUser, unfollowUser } from "redux/users/operations";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const styles = {
  paperContainer: {
    backgroundImage: `url(${Image})`,
  },
};

const PublicProfile = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const currentUser = useSelector((state) => state.auth.profile);
  const { id } = useParams();
  const [userProfile, setUserProfile] = useState(undefined);
  const [value, setValue] = React.useState(2);
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    _loadUserProfile();
  }, [id]);

  const _loadUserProfile = async () => {
    const isMyProfile = !id || (currentUser && currentUser.username === id);
    if (isMyProfile) {
      setUserProfile(currentUser);
      setLoading(false);
    } else {
      try {
        const userProfile = await fetchUserProfile(id);
        setUserProfile(userProfile);
        setLoading(false);
      } catch (error) {
        alert("Unable to get the data of this user, please try again later!");
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Card
          sx={{
            backgroundColor: "#ffff",
          }}
        >
          <div
            style={{
              padding: matchDownSM ? "2rem" : "1rem",
              paddingLeft: matchDownSM ? "2rem" : "2rem",
              display: "flex",
              flexDirection: matchDownSM ? "column" : "row",
              alignItems: matchDownSM ? "center" : "unset",
              justifyContent: matchDownSM ? "center" : "unset",
            }}
          >
            <Grid container spacing={0.5}>
              <Grid lg={1.5} sx={1}>
                <CustomAvatar
                  sx={{
                    width: matchDownSM ? 120 : 150,
                    height: matchDownSM ? 120 : 150,
                  }}
                  item={{
                    username: userProfile.nickname,
                    image: userProfile.image,
                    publisher: userProfile.publisher,
                  }}
                />
              </Grid>
              <Grid
                lg={7.5}
                sx={11}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  paddingLeft: 20,
                }}
              >
                <Typography
                  color={theme.palette.secondary.main}
                  gutterBottom
                  variant={matchDownSM ? "h3" : "h2"}
                >
                  {userProfile.nickname}
                </Typography>
                <Typography fontSize="16px">{userProfile.name}</Typography>
                <Typography variant="caption" fontSize="16px">
                  {userProfile.description}
                </Typography>
                <Grid>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 10,
                      width: "150px",
                      marginTop: "5px",
                    }}
                    onClick={() => {
                      if (userProfile.currentlyFollowing) {
                        dispatch(unfollowUser(userProfile.username));
                      } else {
                        dispatch(followUser(userProfile.username));
                      }
                      // TODO: Add an async request and show a loader to the user
                      _loadUserProfile();
                    }}
                  >
                    {userProfile.currentlyFollowing ? "Unfollow" : "Follow"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {!matchDownSM ? (
              <Grid>
                <Typography
                  textAlign="right"
                  fontSize="25px"
                  fontWeight="bold"
                  color="#F01D22"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {userProfile.followersCount}&nbsp;
                  <Typography
                    textAlign="right"
                    fontSize="18px"
                    fontWeight="bold"
                  >
                    Followers
                  </Typography>
                </Typography>
                <Typography
                  textAlign="right"
                  fontSize="25px"
                  fontWeight="bold"
                  color="#F01D22"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {userProfile.followingCount}&nbsp;
                  <Typography
                    textAlign="right"
                    fontSize="18px"
                    fontWeight="bold"
                  >
                    Following
                  </Typography>
                </Typography>
              </Grid>
            ) : undefined}
          </div>
          {matchDownSM ? (
            <Grid
              lg={3}
              sx={12}
              style={{ display: "flex" }}
              justifyContent="center"
            >
              <Grid sx={6}>
                <Typography
                  textAlign="right"
                  fontSize="25px"
                  fontWeight="bold"
                  color="#F01D22"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    paddingRight: 20,
                  }}
                >
                  {userProfile.followersCount}&nbsp;
                  <Typography
                    textAlign="right"
                    fontSize="18px"
                    fontWeight="bold"
                  >
                    Followers
                  </Typography>
                </Typography>
              </Grid>
              <Grid sx={6}>
                <Typography
                  textAlign="right"
                  fontSize="25px"
                  fontWeight="bold"
                  color="#F01D22"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    paddingLeft: 20,
                  }}
                >
                  {userProfile.followingCount}&nbsp;
                  <Typography
                    textAlign="right"
                    fontSize="18px"
                    fontWeight="bold"
                  >
                    Following
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          ) : undefined}

          <Box
            sx={{
              paddingY: matchDownSM ? "1rem" : "2rem",
              backgroundColor: "#ffff",
              border: 0,
            }}
          >
            <AppBar
              sx={{
                backgroundColor: "#ffff",
              }}
              position="static"
              elevation={0}
            >
              <Tabs
                sx={{
                  backgroundColor: "#ffff",
                  border: 0,
                }}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab
                  label={<span>Published</span>}
                  icon={<YouTubeIcon />}
                  {...a11yProps(0)}
                />
                <Tab
                  icon={<FavoriteIcon />}
                  label={<span>Favourites</span>}
                  {...a11yProps(1)}
                />
                <Tab
                  icon={<PersonPinIcon />}
                  label={<span>Followings</span>}
                  {...a11yProps(2)}
                />
                <Tab
                  icon={<PersonPinIcon />}
                  label={<span>Followers</span>}
                  {...a11yProps(3)}
                />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              {value == 0 && (
                <TimeLineVideoView
                  options={{
                    limit: 20,
                  }}
                  feedGroup="user"
                  username={userProfile.username}
                />
              )}
              {value == 1 && (
                <UserLikesTab
                  options={{
                    limit: 20,
                    kind: "like",
                    user_id: userProfile.username,
                  }}
                  feedGroup="timeline"
                  username={userProfile.username}
                />
              )}
              {value == 2 && <Followings username={userProfile.username} />}
              {value == 3 && <Followings
                isfollowerList={true}
                username={userProfile.username} />}
            </SwipeableViews>
          </Box>
        </Card>
      )}
    </>
  );
};

export default PublicProfile;
