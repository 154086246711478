import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Navigate, Routes, Outlet } from "react-router-dom";
import { URL } from "./Constants/routes";
import NotFound from "./Pages/NotFound/NotFound";
import Home from "./Pages/Home";
import Search from "./Pages/Search";
import MainLayout from "./layout/MainLayout";
import Profile from "./Pages/Profile";
import { Amplify, Auth } from "aws-amplify";
import { StreamApp } from "react-activity-feed";
import { STREAM_CONSTANTS } from "config/getstream";
import { videosActions, videosOperations } from "redux/videos";
import { _checkInitialUser } from "components/organisms/auth-checker/view";
import { AWS_CONFIG } from "./config/aws";
import Login from "./Pages/authentication/authentication3/Login";
import PublicProfile from "./Pages/PublicProfiles";
import ShowLegals from "./Pages/ShowLegals";
import ForgotPassword from "./Pages/ForgotPassword";
import _ from "lodash";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { configureToken } from "api";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { fetchGetStreamToken } from "api";
import { useDispatch } from "react-redux";
import Loading from "ui-component/Loading";
import { logout } from "redux/auth/actions";

Amplify.configure(AWS_CONFIG);
Auth.configure({ oauth: AWS_CONFIG.Auth });

const App = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const isLoggedIn = useSelector(
    (state) => !!(state.auth.user && state.auth.profile)
  );
  const streamToken = useSelector((state) => state.auth.streamToken);
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setToken();
  }, [isLoggedIn]);

  const setToken = async () => {
    try {
      if (isLoggedIn) {
        const signInUserSession = await Auth.currentSession();
        await dispatch(videosOperations.fetchSuggestedLists());
        const tokenUser = _.get(
          signInUserSession,
          "accessToken.jwtToken",
          null
        );
        await configureToken(tokenUser);
        await _checkInitialUser();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      dispatch(logout());
      setLoading(false);
    }
  };

  if (isLoading) return <Loading />;

  const ProtectedRoute = () => {
    if (!isLoggedIn) {
      return <Navigate to={URL.AUTH.LOGIN} replace />;
    }
    return (
      <>
        <StreamApp
          apiKey={STREAM_CONSTANTS.API_KEY}
          appId={STREAM_CONSTANTS.APP_ID}
          token={streamToken}
        >
          <MainLayout>
            <div style={{ width: matchDownSM ? "100%" : "80%" }}>
              <Outlet />
            </div>
          </MainLayout>
        </StreamApp>
      </>
    );
  };
  return (
    <Routes>
      <Route path={URL.AUTH.LOGIN} element={<Login />} />
      <Route path={URL.AUTH.FORGOT_PASSWORD} element={<ForgotPassword />} />

      <Route element={<ProtectedRoute />}>
        <Route path={URL.APP.SEARCH} element={<Search />} />
        <Route path={URL.APP.HOME} element={<Home />} />
        <Route path={URL.APP.PROFILE} element={<Profile />} />
        <Route path={URL.APP.SHOW_LEGAL} element={<ShowLegals />} />
        <Route path={URL.APP.PUBLIC_PROFILE} element={<PublicProfile />} />
        <Route
          path={URL.APP.PUBLIC_PROFILE + "/:id"}
          element={<PublicProfile />}
        />
        <Route path={URL.NOT_FOUND} element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default App;
