import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LogoSection from "../LogoSection";
import NotificationSection from "./NotificationSection";
import ProfileSection from "./ProfileSection";
import SearchSection from "./SearchSection";
import { URL } from "Constants/routes";

import { useNavigate } from "react-router-dom";
const Header = (props) => {

  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: 150,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: {// xs: "none",
           md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>
      </Box>


      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      {!props.search && <SearchSection
        onClick={() => navigate(`/${URL.APP.SEARCH}`)}
        disable={true} />}
      <NotificationSection />
      <ProfileSection />
    </>
  );
};

export default Header;
