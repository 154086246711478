import React from 'react';
import logo from 'Assets/images/src_assets_images_logo_animated.gif'
function Loading(props) {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      className="App">
      <img
        style={{ width: '200px', height: '100px' }}
        src={logo} alt="loading..." />
      {props.isEmpty ? <p>No Record Found</p>
        : props.isLoading ?
          <p>Loading</p> : null

      }
    </div>
  );
}
export default Loading;