export const URL = {
  AUTH: {
    LOGIN: "/",
    REGISTER: "register",
    FORGOT_PASSWORD: "forgot-password",
  },
  APP: {
    HOME: "home",
    PROFILE: "profile",
    PUBLIC_PROFILE: "public-profile",
    SHOW_LEGAL: "showlegals",
    SEARCH: "search",
  },
  NOT_FOUND: "*",
};
