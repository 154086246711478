import * as types from "./types";

export const setAppInit = () => ({
  type: types.SET_AUTH_INIT,
});

export const setUser = (value) => ({
  type: types.SET_AUTH_USER,
  payload: value,
});

export const setProfile = (value) => ({
  type: types.SET_AUTH_PROFILE,
  payload: value,
});

export const setGetStreamToken = (value) => ({
  type: types.SET_AUTH_GETSTREAM_TOKEN,
  payload: value,
});

export const setStreamToken = (value) => {
  console.log(
    "🚀 ~ file: actions.js ~ line 23 ~ setStreamToken ~ value",
    value
  );
  return {
    type: types.SET_STREAM_TOKEN,
    payload: value,
  };
};

export const setFetching = (isFetching, isFetchingInfo = "") => {
  return {
    type: types.SET_AUTH_FETCHING,
    payload: { isFetching, isFetchingInfo },
  };
};

export const setInvitationCode = (value) => ({
  type: types.SET_INVITATION_CODE,
  payload: value,
});

export const setUserSession = (value) => ({
  type: types.SET_USER_SESSION,
  payload: value,
});

export const setStartApp = (value) => ({
  type: types.START_APP,
  payload: value,
});

export const setHasOpenAppBefore = (value) => ({
  type: types.SET_HAS_OPEN_APP_BEFORE,
  payload: value,
});

export const logout = () => ({
  type: types.LOGOUT,
});
