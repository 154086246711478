import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Typography, useMediaQuery, Grid } from "@mui/material";
import FollowedCard from "ui-component/cards/FollowedCard";
import DiscoverFollowedCard from "ui-component/cards/DiscoverFollowedCard";
import ReactPlayer from "react-player";


export const RenderVideo = React.memo(({ item, isUrl, idx, search, discover, fromFollowing }) => {


  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  var publisherData;
  if (isUrl) {
    publisherData = item.actor.data;
    publisherData["username"] = item.actor.id;
  }

  return (
    <Grid item xs={search ? 2 : 0} sm={search ? 4 : 0} md={search ? 4 : 0}>
      <div
        style={{
          width: matchDownSM ? "100%" : search?'96%' : "80%",
      display: "flex",
      flexDirection: "column",
      margin: "auto",
        }}
      >

      {discover ? <DiscoverFollowedCard
        discover={discover}
        search={search}
        sx={{ width: 50, height: 50 }}
        index={idx} item={isUrl ? publisherData : item.owner} /> :
        <FollowedCard
          allData={item}
          fromFollowing={fromFollowing}
          search={search}
          sx={{ width: 50, height: 50 }}
          index={idx} item={isUrl ? publisherData : item.owner} />}


      <div
        style={{
          background: "black",
          borderRadius: matchDownSM || search ? 0 : 30,
          //  padding: matchDownSM ? 0 : 20,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >


        <ReactPlayer
          url={isUrl ? item.trailerUrl : item.trailer}
          // playing
          config={{
            youtube: {
              playerVars: { showinfo: 1 },
            },
            facebook: {
              appId: "12345",
            },
          }}
          height={matchDownSM && !search ? "200px" : search ? "300px" : "600px"}
          width="100%"
          controls
        />
      </div>

      <Typography
        color={"black"}
        gutterBottom
        sx={{ margin: 2 }}
        variant={"h4"}
      >
        {item.title}
      </Typography>
    </div>
    </Grid >
  );
});
