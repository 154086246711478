import React from 'react';

export const FeedEmptyComponent = () => {


  return (
    <div>
      <p>No Record Found</p>
    </div>
  );
};
