import React from "react";
// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, Typography } from "@mui/material";
import { getAvailableVideos } from "utils";

import { useSelector } from "react-redux";

const NotificationSection = () => {
  const theme = useTheme();

  const user = useSelector((state) => state.auth.user);
  const coins = getAvailableVideos(user);
  return (
    <>
      <Box
        sx={{
          ml: 2,
          mr: 3,
          [theme.breakpoints.down("md")]: {
            mr: 2,
          },
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            alignItems: "center",
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumsAvatar,
            transition: "all .2s ease-in-out",
            background: theme.palette.secondary.light,
            color: theme.palette.secondary.dark,
          }}
          aria-haspopup="true"
          color="inherit"
        >
          <img
            style={{ width: 20, height: 20, marginRight: 5 }}
            src={require("./../../../../Assets/images/ic_play_coins.png")}
          />
          <Typography
            sx={{
              color: theme.palette.secondary.dark,

              marginX: "2px",
              mt: "2px",
            }}
            variant="h4"
          >
            {coins}
          </Typography>
        </Avatar>
      </Box>
    </>
  );
};

export default NotificationSection;
