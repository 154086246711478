import React from "react";
import { persistor, store } from "./store";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import theme from "./Theme";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import "./index.css";
import { SnackbarProvider } from 'notistack';


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={theme()}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>

        <BrowserRouter>
        <SnackbarProvider maxSnack={2}>
          <App />
          </SnackbarProvider>
        </BrowserRouter>

      </PersistGate>
    </Provider>
  </ThemeProvider>
);

reportWebVitals();
