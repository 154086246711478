export const text = {





    // "search": {
    //     "placeholder": "Search",
    //     "interests": "Based on your interests",
    //     "videos": "Videos",
    //     "users": "Users"
    // },

    // "profile": {
    //     "follow": "Follow",
    //     "unfollow": "Following",
    //     "followed": "Following",
    //     "followers": "Followers",
    //     "tabFeed": "Published",
    //     "tabMyVideos": "My videos",
    //     "tabPurchased": "Purchased",
    //     "tabFavs": "Favourites",
    //     "unfollowCelebrity":"Following",
    //     "followCelebrity": "Follow"
    // },
    // "settings": {
    //     "stats": "Stats",
    //     "billing": "Billing",
    //     "editProfile": "Edit profile",
    //     "changPassword": "Change password",
    //     "logout": "Log out",
    //     "back": "Back",
    //     "terms":"Terms and conditions",
    //     "privacity":"Privacy policies",
    //     "legalAdvice": "Legal notice",
    //     "invite":"Invite to Beta"
    // },
    // "videoUpload": {
    //     "publicSeconds": "These seconds will be public",
    //     "addCategory": "Add category",
    //     "uploadVideoSucccessProgress": "%{percentCompleted}% of video uploaded.",
    //     "uploadVideoSucccessMessage": "Video successfully uploaded"
    // },
    // "updateProfile": {
    //     "photoPickerTitle": "Select your profile photo"
    // },
    // "feedCard": {
    //     "buyButtonLabel": "Slide to watch video"
    // },
    // "purchaseModal": {
    //     "availableVideosLabel": "Available for you to watch",
    //     "purchaseLabel": "To unlock more videos buy a bundle",
    //     "purchaseAdvice": "To avoid scams, never buy credits from other parties than Bethey.com and never give your password to anyone.",
    //     "purchaseInAppButtonLabel": "Buy"
    // },
    // "dateTimePickerModal": {
    //     "label": "Chose video publishing date",
    //     "submitLabel": "Program publishing",
    //     "backLabel": "Back"
    // },
    // "categoriesPickerModal": {
    //     "label": "Choose label",
    //     "backLabel": "Cancel"
    // },
    // "videoFormSubmitModal": {
    //     "label": "¿When do you want to publish this video?",
    //     "opt1": "Now",
    //     "opt2": "Keep private",
    //     "opt3": "Program publishing",
    //     "backLabel": "Cancel"
    // },
    // "newActivitiesNotification": {
    //     "singleNotificationText": "You have a new notification",
    //     "pluralNotificationText": "You have %{notificationCount} new notifications"
    // },
    // "comments": {
    //     "addComment": "Add comment",
    //     "viewMore": "View more",
    //     "report": "Report",
    //     "delete": "Delete",
    //     "reportTitle": "Why do you want to report this content?",
    //     "reportIsSpamReason": "It’s SPAM",
    //     "reportOffensiveReason": "It’s offensive",
    //     "reportSubmit": "Continue and report"
    // },
    // "videos": {
    //     "editVideo": "Edit video",
    //     "updateToPrivate": "Update to private",
    //     "updateToPublic": "Update to public",
    //     "updateToProgrammed": "Program publishing",
    //     "updateProgrammedDate": "Edit programmed date",
    //     "scheduleVideo": "Program publishing",
    //     "shareVideo": "Share video",
    //     "deleteVideo": "Delete video",
    //     "reportVideo": "Report video",
    //     "blockUser": "Block user",
    //     "reportTitle": "Why do you want to report this content?",
    //     "reportIsSpamReason": "It’s SPAM",
    //     "reportOffensiveReason": "It’s offensive",
    //     "reportSubmit":"Continue and report",
    //     "shareVideoTitle": "Log in BeThey to watch this video",
    //     "shareVideoMessage": "Haven't seen this exclusive video on Bethey yet?",
    //     "startingUpload": "Starting upload",
    //     "recommendationsTitle": "Recommended for you",
    //     "viewsLabel": "%{views} Views"
    // },
    // "deleteModal": {
    //     "title": "The video is going to be deleted",
    //     "message": "Are you sure you want to continue? Users who have paid for its viewing will cease to have access to it.",
    //     "action": "Continue and delete"
    // },
    // "stats": {
    //     "yesterdayTab":"Yesterday",
    //     "sevenTab":"7 days",
    //     "thirtyTab":"30 days",
    //     "yesterdayTitle":"Yesterday you had",
    //     "yesterdayDescription":"Compared to the previous day",
    //     "sevenTitle":"This week you had",
    //     "sevenDescription":"Compared to last week",
    //     "monthTitle":"This month you had",
    //     "monthDescription":"Compared to last month",
    //     "views":"Views",
    //     "followers":"Followers",
    //     "likes":"Likes"
    // },
    // "billing": {
    //     "billingTab": "Income",
    //     "shareCodeTab": "Referrals",
    //     "totalIncome": "Total Income",
    //     "friendsIncome": "Earnings from invites",
    //     "inviteFriends": "Invite your friends to Bethey and earn a monthly bonus for it.",
    //     "shareCodeLabel": "Share this code with them:",
    //     "shareCodeAction": "Share code",
    //     "copyCodeLabel": "Copied code",
    //     "shareCodeTitle": "Join BeThey",
    //     "shareCodeMessage": "Start using BeThey. Register with the code",
    //     "startBethey": "Since you started with Bethey",
    //     "historicTitle": "Earnings history",
    //     "beneficiariesTitle": "Donations to NGOs",
    //     "beneficiariesOptionsLabel": "Beneficiary NGO",
    //     "beneficiariesPercentLabel": "Earnings granted (%)",
    //     "beneficiariesModalTitle": "Beneficiary NGO",
    //     "lastXdays": "Last %{days} days",
    //     "inviteAppend": "Remember that Bethey only allows creators with relevance in other platforms and need to be approved by our team."
    // },
    // "terms":{
    //     "read1":"I have read and accepted the",
    //     "read2":"privacy policy, ",
    //     "read3":"as well as the ",
    //     "read4":"terms and conditions of use ",
    //     "read5":"of the platform",
    //     "back":"BACK"
    // },
    // "notifications": {
    //     "newVideoTitle": "New Video",
    //     "newVideoBody": "%{authorName} has uploaded a new video. Do you want to view it?"
    // },
    // "invitations":{
    //     "invite":"Invite your friends to enjoy the Bethey beta",
    //     "share":"Share this code with them or click to copy it directly",
    //     "share_code":"Share code",
    //     "you_have":"You have",
    //     "you_have2":"remaining activationss",
    //     "shareCodeTitle": "Join BeThey",
    //     "shareCodeMessage": "Start using BeThey to watch exclusive content from your favourite stars. Register for free with this code: %{code}"
    // },
    // "discover": {
    //     "title": "Discover",
    //     "sub-title": "other celebs you can follow"
    // }
}

export const ResVariant =
{
    success: 'success',
    error: 'error',
    warning: 'warning',
    info: 'info',
}

export const alerts = {
    great: "Great",
    registerSucceed: "User registered. Check your email and enter the code we sent you",
    warning: "Warning",
    mustAccpetTerms: "You must accept the terms and conditions and be over 16 years old",
    verifiedCode: "Verified code. You can already log in",
    codeResent: "Code resent",
    passMustMatch: "Passwords must match",
    passUpdateSuccess: "Successful password update",
    videoMaxDuration: "The video must not exceed %{maxDuration} seconds",
    videoMaxSize: "The video must not exceed %{maxSize} mb"
}
export const
    errors = {
        error: "Error",
        request: "Request error. Check internet connection",
        emailOrPassword: "Wrong user and/or password",
        isRequired: "Required field",
        wrongEmail: "Wrong email",
        mailValidationMessage: "Minimum 8 characters, low, caps, num",
        register: "Registering error",
        verifyingCode: "Verifying code error",
        sendingCode: "Resending code error",
        videoRecordingError: "There has been a video recording error",
        videoSelectError: "There has been an error when selecting the video",
        aliasInUse: "This nickname is already in use",
        emailInUse: "This email is already in use",
        referralCode: "Invalid referral code",
        uploadVideoError: "There has been an error when uploading the video",
        tooShortAlias: "The nickname must be at least 3 characters long",
        tooShortName: "The short name must be at least 3 characters long",
        updateProfileError: "Your profile couldn't be updated",
        passwordUpdateError: "Your password wasn’t successfully changed",
        uploadVideoFormValidation: "Add a title and choose a category to continue with the video upload",
        tooLongDescription: "Too long. Max 60 characters",
        errorBeta: "To conclude register, an activation code is mandatory"
    }


export const dashbaord = {
    Following: "Following",
    Discover: "Discover",
    "follow": "Follow",
    "unfollow": "Following",
    "followed": "Following",
    "followers": "Followers",
    "tabFeed": "Published",
    "tabMyVideos": "My videos",
    "tabPurchased": "Purchased",
    "tabFavs": "Favourites",
    "unfollowCelebrity": "Following",
    "followCelebrity": "Follow"
}
export const form = {
    "welcome": "Welcome to",
    "email": "Email",
    "password": "Password",
    "login": "Login",
    "fbLogin": "Login through Facebook",
    "signup": "Sing me up",
    "becomeCreator": "Become a content creator",
    "createAccount": "Create your account",
    "nickname": "Nickname",
    "name": "Name",
    "referralCode": "Referral Code (optional)",
    "accept": "Accept ",
    "termsAndCond": "Terms and conditions",
    "age": "I’m over 16",
    "recoverPassword": "Recover password",
    "recoverPasswordEmailText": "Text your registered email address in order to recover your password",
    "recoverPasswordCodeText": "We have sent you an email. You’ll find an alphanumeric code in it. Enter the code in the form to change your password.",
    "sendEmail": "Send email",
    "code": "Code",
    "sendCode": "Accept",
    "resendCode": "Resend code",
    "titleBeforePublish": "Register a title before publishing",
    "passwordPlacehoder": "Caps + low+ num + character",
    "currentPassword": "Current password",
    "newPassword": "New password",
    "repeatNewPassword": "Repeat new password",
    "description": "Description",
    "legalAdvice": "Legal notice",
    "noFollow": "It seems you are not following anyone yet. Start your journey in BeThey by using the search engine in order to find people to follow"

}
