import React, { useState, useEffect } from "react";
// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  IconButton,
} from "@mui/material";

import AuthCardWrapper from "../authentication/AuthCardWrapper";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, TextField } from "@mui/material";

import { authOperations } from "redux/auth";
import AnimateButton from "ui-component/extended/AnimateButton";
import { IconLoader } from "@tabler/icons";

const UserInfo = (props) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const user = useSelector((state) => state.auth.profile);
  const dispatch = useDispatch();
  const [description, setDescription] = useState(user.description);
  const [username, setUsername] = useState(user.email);
  const [name, setName] = useState(user.name);
  const [isEditable, setIsEditable] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    let data = { nickname: username, name: name, description: description };
    dispatch(authOperations.updateUserProfile(data));
    props.toggleModal();
    setLoading(false);
  };

  return (
    <div style={{ padding: 5 }}>
      {/* <Grid container alignItems="flex-end" justifyContent="flex-end">
        <IconButton color="primary" onClick={() => setIsEditable(!isEditable)}>
          {isEditable ? <SaveIcon /> : <EditIcon />} 
        </IconButton>
      </Grid> */}
      <>
        <Grid
          container
          direction={matchDownSM ? "column-reverse" : "row"}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Stack alignItems="center" justifyContent="center" spacing={1}>
              <Typography
                color={theme.palette.secondary.main}
                gutterBottom
                variant={matchDownSM ? "h3" : "h2"}
              >
                User Profile
              </Typography>
              <Typography
                variant="caption"
                fontSize="20px"
                textAlign={matchDownSM ? "center" : "inherit"}
              >
                Edit your profile data
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={matchDownSM ? 0 : 2}>
            <Grid item xs={12} sm={6}>
              <TextField
                // disabled={!isEditable}
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                fullWidth
                label="Username"
                margin="normal"
                name="Username"
                variant="standard"
                type="text"
                defaultValue=""
                sx={{ ...theme.typography.customInput }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // disabled={!isEditable}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                variant="standard"
                label="Name"
                margin="normal"
                name="Name"
                type="text"
                defaultValue=""
                value={name}
                sx={{ ...theme.typography.customInput }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // disabled={!isEditable}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                value={description}
                variant="standard"
                label="Description"
                margin="normal"
                name="Description"
                type="text"
                defaultValue=""
                sx={{ ...theme.typography.customInput }}
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              mt: 5,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <AnimateButton sx={{ mt: 8 }}>
              <Button
                onClick={() => onSubmit()}
                disableElevation
                disabled={loading}
                //fullWidth
                sx={{ width: "150px" }}
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
              >
                {loading ? (
                  <IconLoader />
                ) : (
                  <>
                    <SaveIcon sx={{ mr: 2 }} />
                    Save
                  </>
                )}
              </Button>
            </AnimateButton>
          </Box>
        </Grid>
      </>
    </div>
  );
};

export default UserInfo;
