import _ from 'lodash'
import { PUBLISHER_GROUP, BANNED_GROUP } from '../config/aws'

export const checkIsPublisher = user => {
  const cognitoGroups = _.get(user, 'signInUserSession.accessToken.payload.cognito:groups', [])
  const isPublisher = cognitoGroups.includes(PUBLISHER_GROUP)
  return isPublisher
}

export const checkIsBanned = user => {
  const cognitoGroups = _.get(user, 'signInUserSession.accessToken.payload.cognito:groups', [])
  const isBanned = cognitoGroups.includes(BANNED_GROUP)
  return isBanned
}

export const checkIsSameUser = (user1, user2) => {
  return _.get(user1, 'username', 1) === _.get(user2, 'username', 2)
}

export const getAvailableVideos = user => {
  return _.toInteger(_.get(user, 'attributes.custom:available_videos', 0))
}

export const filterValidVideos = list => {
  return _.filter(list, item => !_.isNil(_.get(item, 'id')) && !_.isNil(_.get(item, 'thumbnail')))
}

// eslint-disable-next-line no-bitwise
export const secondsToTime = time => ~~(time / 60) + ':' + (time % 60 < 10 ? '0' : '') + (time % 60)

export const isFreeVideo = video => {
  return video.free || false
}
