import PropTypes from 'prop-types';
import { useState } from 'react';
import { URL } from "Constants/routes";
// material-ui
import IconButton from '@mui/material/IconButton';
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Card, Grid, InputAdornment, OutlinedInput, Popper } from '@mui/material';

// third-party
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports
import Transitions from './../../../../ui-component/extended/Transitions';
import { useNavigate } from "react-router-dom";
// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from "@tabler/icons";
import { shouldForwardProp } from "@mui/system";

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
    zIndex: 1100,
    width: "99%",
    top: "-55px !important",
    padding: "0 12px",
    [theme.breakpoints.down("sm")]: {
        padding: "0 10px",
    },
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
    ({ theme }) => ({
        width: '340px',
        //  width: "80%",
        marginLeft: 16,
        paddingLeft: 16,
        paddingRight: 16,
        "& input": {
            background: "transparent !important",
            paddingLeft: "4px !important",
        },
        [theme.breakpoints.down("lg")]: {
            //     width: 250,

            width: '340px',
        },
        [theme.breakpoints.down("md")]: {

            width: "100%",
            marginLeft: 4,
            background: "#fff",
        },
    })
);

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
    ({ theme }) => ({
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        background: theme.palette.secondary.light,
        color: theme.palette.secondary.dark,
        "&:hover": {
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.light,
        },
    })
);

const HeaderAvatarStyle2 = styled(Avatar, { shouldForwardProp })(
    ({ theme }) => ({
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        background: theme.palette.secondary.light,
        color: theme.palette.secondary.dark,
        width: '150px',
        "&:hover": {
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.light,
        },
    })
);

// ==============================|| SEARCH INPUT - MOBILE||============================== //



// ==============================|| SEARCH INPUT ||============================== //
//
const SearchSection = (props) => {
    const theme = useTheme();
    // const [value, setValue] = useState('');
    const { value, setValue } = props;
    const navigate = useNavigate();
    return (
        <>
            {
                props.disable ?

                    <>
                        <Box sx={{ display: { xs: props.search && 'none', md: 'none' } }}

                        >

                            <ButtonBase

                                sx={{ borderRadius: '12px', }}>
                                <HeaderAvatarStyle
                                    onClick={() => navigate(`/${URL.APP.SEARCH}`)}
                                    variant="rounded">
                                    <IconSearch stroke={1.5} size="1.2rem" />
                                </HeaderAvatarStyle>
                            </ButtonBase>

                        </Box>
                        <Box sx={{
                            display: {
                                xs: !props.search && 'none',
                                md: 'block'
                            }
                        }}>

                            <ButtonBase

                                sx={{ borderRadius: '12px', }}>
                                <HeaderAvatarStyle
                                    onClick={() => navigate(`/${URL.APP.SEARCH}`)}
                                    variant="rounded">
                                    <IconSearch stroke={1.5} size="1.2rem" />
                                </HeaderAvatarStyle>
                            </ButtonBase>
                            {/* <ButtonBase

                                sx={{ borderRadius: '12px', }}>
                                <HeaderAvatarStyle2
                                    onClick={() => navigate(`/${URL.APP.SEARCH}`)}
                                    variant="rounded"
                                >
                                    <p>Search</p>
                                    <IconSearch stroke={1.5} size="1.2rem" />
                                </HeaderAvatarStyle2>
   
                            </ButtonBase> */}

                            {/* <OutlineInputStyle
                                onClick={props.onClick}
                                disabled={props.disable}
                                id="input-search-header"
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                placeholder="Search"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                                    </InputAdornment>
                                }

                                aria-describedby="search-helper-text"
                                inputProps={{ 'aria-label': 'weight' }}
                            /> */}
                        </Box>
                    </> :



                    <>
                        <Box sx={{ display: { xs: props.search && 'none', md: 'none' } }}

                        >

                            <ButtonBase

                                sx={{ borderRadius: '12px', mt: 1 }}>
                                <HeaderAvatarStyle
                                    onClick={() => navigate(`/${URL.APP.SEARCH}`)}
                                    variant="rounded">
                                    <IconSearch stroke={1.5} size="1.2rem" />
                                </HeaderAvatarStyle>
                            </ButtonBase>

                        </Box>
                        <Box sx={{
                            display: {
                                xs: !props.search && 'none',
                                md: 'block'
                            }
                        }}>
                            <OutlineInputStyle
                                onClick={props.onClick}
                                disabled={props.disable}
                                id="input-search-header"
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                placeholder="Search"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                                    </InputAdornment>
                                }
                                //  endAdornment={
                                //     <InputAdornment position="end">
                                //         <ButtonBase sx={{ borderRadius: '12px' }}>
                                //             <HeaderAvatarStyle variant="rounded">
                                //                 <IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" />
                                //             </HeaderAvatarStyle>
                                //         </ButtonBase>
                                //     </InputAdornment>
                                // }
                                aria-describedby="search-helper-text"
                                inputProps={{ 'aria-label': 'weight' }}
                            />
                        </Box>
                    </>
            }
        </>
    );
};

export default SearchSection;
